import React from "react";
import HeaterList from "../components/heater-list"
import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero"
import { Link } from "gatsby"

export default function Electric({ data, pageContext }) {
  return (
    <Layout>
    
      <SEO
        title="Electric Patio Headers"
        keywords={[`electric patio heaters`, `electric garden heaters`, `electric outdoor heaters`]}
        description="Looking for the best electric patio heater? We've hand-picked the gas patio heaters on the market for you for explore."
      />

<Hero heading='Electric Patio Heaters' subheading='Convenient plug and play heating' />

<div className="flex flex-wrap w-full container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
<div className="p-6">
<h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">The best electric patio heaters.</h3>
<p className="text-gray-600">We've hand-picked the best patio heaters on the market for you for explore.<br /><br /></p>
<HeaterList data= {data.products} />
</div>
</div>

<div className="flex flex-wrap w-full container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
<div className="p-6">
<h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">Why choose an electric patio heater?</h3>
<p className="text-gray-600 mb-2">Electric patio heaters offer several benefits over gas or other natural flames.</p>
<p className="text-gray-600 mb-2"><strong>Fuel source</strong> - Since you use electricity, you only need concern yourself with having a power point or extension lead that reaches. Once plugged in you can essentially forget about fuel.</p>
<p className="text-gray-600 mb-2"><strong>Quick</strong> - Electric heaters use bulbs that can warm up very fast, and turn off quickly too.</p>
<p className="text-gray-600 mb-4"><strong>Direct</strong> - Electric heaters use infrared light, so it can be directed where you want it, and it doesn't lose much heat to the surrounding air.</p>.
<p className="text-gray-600 mb-2">There are drawbacks - you're tied to a power supply or an extention cord - and some people prefer the aesthetic value or a real flame. If that is you, you might want to consider a <Link to="/gas-heaters/">gas heater</Link>.</p>
</div>
</div>

    </Layout>
  );
}

export const query = graphql`
  query {
    products: allGoogleSheetProductsRow(filter: {powersource: {eq: "Electric"}}) {
      edges {
        node {
          productImage
          productName
        }
      }
    }
  }
`